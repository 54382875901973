import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  Product,
  ProductData,
  ProductQueryData,
  Supplier,
} from './types/shop';

const artcodesUrl = '/assets/json/artcodes.json';
const brandsUrl = '/assets/json/brands.json';
const brandsPath = '/assets/json/brands/';
const chipsUrl = '/assets/json/chips.json';
const chipsPath = '/assets/json/chips/';
const productsPath = '/assets/json/products/';
const suppliersPath = '/assets/json/suppliers/';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  private ArtcodesSubject = new BehaviorSubject<Array<number>>([]);
  private BrandsSubject = new BehaviorSubject<Array<string>>([]);
  private RandomSubject = new BehaviorSubject<Array<Product>>([]);
  private ChipsSubject = new BehaviorSubject<Array<string>>([]);
  private ProductDataSubject = new BehaviorSubject<ProductData | null>(null);
  private ProductsSubject = new BehaviorSubject<Array<Product>>([]);
  private SuppliersSubject = new BehaviorSubject<Array<Supplier>>([]);
  private artcodes:Array<number> = [];
  private productCount = 0;
  constructor(private httpClient: HttpClient) {
    this.httpClient.get<Array<string>>(chipsUrl).subscribe((chips) => {
      this.ChipsSubject.next(chips);
    });
    this.httpClient.get<Array<string>>(brandsUrl).subscribe((brands) => {
      this.BrandsSubject.next(brands);
    })
    this.httpClient.get<Array<number>>(artcodesUrl).subscribe((artcodes) => {
      this.ArtcodesSubject.next(artcodes);
      //console.log('ProductService artcodes: ', artcodes);
      this.artcodes = [...artcodes];
      this.productCount = artcodes.length;
      //console.log('ProductService productCount: ', this.productCount);
      this.setRandomProducts(3);
    });
  }
  private setRandomProducts = async (count:number) => {
    let products: Array<Product> = [];
    let randoms: Array<number> = [];
    for (let i = 0; i < count; i++) {
      let newRandom = false;
      while (!newRandom) {
        let random = Math.random();
        //console.log('ProductService random: ', random);
        //console.log('ProductService this.productCount: ', this.productCount);
        let productRandom = Math.ceil(random * this.productCount);
        //console.log('ProductService productRandom: ', productRandom);
        if (!(random in randoms)) {
          newRandom = true;
          //console.log('ProductService this.artcodes: ', this.artcodes);
          //console.log('Productservice typeof productRandom: ', typeof productRandom);
          //console.log('Productservice typeof this.artcodes: ', typeof this.artcodes);
          //console.log('ProductService this.artcodes[random]: ', this.artcodes[productRandom]);
          let product = await this.getProduct(this.artcodes[productRandom]);
          randoms.push(random);
          products.push(product);
        }
      }
    }
    this.RandomSubject.next([...products]);
  }
  getBrands(): Observable<Array<string>> {
    return this.BrandsSubject.asObservable();
  }
  getChips(): Observable<Array<string>> {
    return this.ChipsSubject.asObservable();
  }
  getProduct = async (artcode: number): Promise<Product> => {
    //console.log('getProduct artcode: ', artcode);
    return new Promise((resolve, reject) => {
      this.httpClient.get<Product>(`${productsPath}${artcode}.json`)
      .subscribe(product => {
        //console.log('getProduct product with artcode : ',artcode, ' ', product );
        resolve(product);
      }, err => {
        reject(err);
      })
    })
  }
  getProductData(data: ProductQueryData) {
    let { list, query } = data;
    let url = chipsUrl;
    switch (list) {
      case 'chips':
        url = chipsPath + query + '.json';
        break;
      case 'suppliers':
        url = suppliersPath + query + '.json';
        break;
      default:
        break;
    }
    this.httpClient.get<ProductData>(url).subscribe((data) => {
      this.ProductDataSubject.next(data);
    });
  }
  getProducts(): Observable<Array<Product>> {
    return this.ProductsSubject.asObservable();
  }
  getRandomProducts(): Observable<Array<Product>> {
    return this.RandomSubject.asObservable();
  }
  getSuppliers(): Observable<Array<Supplier>> {
    return this.SuppliersSubject.asObservable();
  }
}
