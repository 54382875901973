import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ShopService } from '../shop.service';
import { Cart, CartItem } from '../types/shop';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit {
  @Input() mode: string = 'cart';
  @Input()
  set data(data: Array<CartItem>) {
    console.log('CartComponent set data: ', data);
    if (data) {
      this._data = [...data];
      let newTotal = 0;
      for (let item of this._data) {
        newTotal += item.price * item.quantity;
      }
      this.total = newTotal;
      this.cart.items = [...this._data];
      this.dataSource = of(this._data);
    } else {
      this._data = [];
      this.dataSource = of([]);
    }
  }
  get data(): Array<CartItem> {
    return [...this._data];
  }
  private _data: Array<CartItem> = [];
  cart: Cart;
  zero: boolean = false;
  bo: boolean = false;
  //cart: Cart | null = null;
  dataSource = new Observable<CartItem[]>();
  //dataSource = new MatTableDataSource<CartItem>([]);

  display: string[] = [
    'artcode',
    'image',
    'description',
    'price',
    'quantity',
    'subtotal',
    'delete',
  ];
  displayFooter: string[] = [
    'artcode',
    'image',
    'description',
    'price',
    'quantity',
    'subtotal',
  ];
  total: number = 0;
  constructor(private shopService: ShopService) {
      this.cart = {
        name: '',
        created: 0,
        hasBo: false,
        items: []
      }
  }

  ngOnInit(): void {
    if (this.mode == 'success') {
      console.log('CartComponent mode == success');
    } else {
      this.shopService.getCart().subscribe(cartObject => {
        let cart: Cart = cartObject as Cart;
        let zero = false;
        let bo = false;
        this.cart = {...cart};
        this.data = [...cart.items];
        for (let item of cart.items) {
          if (item.quantity == 0) {
            zero = true;
          }
          if (item.quantity > item.stock){
            console.log('CartComponent onInit item: ', item);
            bo = true;
          }
        }
        this.zero = zero;
        this.bo = bo;
        console.log('CartComponent onInit this.cart: ', this.cart);
      })
      /*
      this.dataSource = this.shopService.getCart().pipe(
        map((cart: Cart | null) => {
          if (cart) {
            return cart.items;
          } else {
            return [];
          }
        }),
        tap((items) => {
          this.bo = false;
          this.zero = false;
          let newTotal = 0;
          for (let item of items) {
            newTotal += item.price * item.quantity;
            if (item.stock && item.quantity > item.stock) {
              this.bo = true;
            }
            if (item.quantity == 0) {
              this.zero = true;
            }
          }
          this.total = newTotal;
        })
      );
      */
    }
  }
  decreaseQuantity(item: CartItem) {
    console.log('CartComponent decreaseQuantity item: ', item);
    this.shopService.decreaseQuantity(item);
  }
  increaseQuantity(item: CartItem) {
    console.log('CartComponent increaseQuantity item: ', item);
    this.shopService.increaseQuantity(item);
  }
  delete(artcode: string) {
    this.shopService.deleteFromCart(artcode);
  }
}
