<mat-card [ngClass]="mode">
  <mat-card-header>
    <mat-card-title *ngIf="mode != 'mini'"
      ><span>{{ supplier }}: </span
      ><span>{{ product.reference }}</span></mat-card-title
    >
  </mat-card-header>
  <img
    mat-card-image
    [routerLink]="['/product/' + product.artcode]"
    [src]="image"
    [alt]="product.description"
    [ngClass]="mode"
  />
  <mat-card-content>
    <mat-divider></mat-divider>
    <div [ngClass]="[mode, 'description']">{{ product.description }}</div>
    <mat-list *ngIf="mode != 'mini'">
      <mat-list-item *ngIf="mode == 'detail'">
        <div matListItemLine>
          <a [routerLink]="'/supplier/'+product.supplier">Meer producten van leverancier {{ product.supplier | uppercase }}.</a>
        </div>
      </mat-list-item>
      <mat-list-item>
        <mat-icon matListItemIcon svgIcon="openInNew"></mat-icon>
        <div matListItemLine>
          <a [href]="product.url" target="_blank">{{ supplier }} website</a>
        </div>
      </mat-list-item>
      <mat-list-item>Electroshop: #{{ product.artcode }}</mat-list-item>
      <mat-list-item
        >
        <mat-icon
          matListItemIcon
          *ngIf="product.status == 'E'"
          svgIcon="opIsOp"
        ></mat-icon>
        <mat-icon
          matListItemIcon
          *ngIf="product.status == 'N'"
          svgIcon="thumbUp"
        ></mat-icon>
        €{{ product.price }} / Stock: {{ product.stock }}</mat-list-item
      >
    </mat-list>
    <div class="addToCart" *ngIf="mode != 'mini'">
      <button (click)="decreaseQuantity()" mat-icon-button>
        <mat-icon svgIcon="deleteCircle"></mat-icon>
      </button>
      <button [routerLink]="'/cart'" mat-icon-button>
        <mat-icon svgIcon="cart" [matBadge]="quantity"></mat-icon></button>
      <button (click)="increaseQuantity()" mat-icon-button>
        <mat-icon svgIcon="addCircle"></mat-icon>
      </button>
    </div>
    <div *ngIf="mode == 'detail'" [innerHTML]="info">
    </div>
  </mat-card-content>
</mat-card>
