import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductService } from '../product.service';
import { Product } from '../types/shop';

@Component({
  selector: 'app-random-products',
  templateUrl: './random-products.component.html',
  styleUrls: ['./random-products.component.scss']
})
export class RandomProductsComponent implements OnInit {
  @Input() count: number = 1;
  products: Observable<Array<Product>> = new Observable()
  constructor(
  private productService: ProductService
) {}
ngOnInit(): void {
    this.products = this.productService.getRandomProducts();
}
}
