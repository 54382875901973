<div class="cart">
    <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="artcode">
            <th mat-header-cell *matHeaderCellDef>Artikel</th>
            <td mat-cell *matCellDef="let item" class="link" [routerLink]="'/product/'+item.artcode">{{item.artcode}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="image">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let item" class="link image" [routerLink]="'/product/'+item.artcode">
                <img *ngIf="item.image" [src]="'assets/img/'+item.image"></td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef>Beschrijving</th>
            <td mat-cell *matCellDef="let item" class="link" [routerLink]="'/product/'+item.artcode">{{item.description}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="price">
            <th mat-header-cell *matHeaderCellDef>Prijs</th>
            <td mat-cell *matCellDef="let item">{{item.price | currency:'EUR'}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="quantity">
            <th mat-header-cell *matHeaderCellDef class="center">Aantal</th>
            <td mat-cell *matCellDef="let item"  [ngClass]="{'zero': item.quantity == 0, 'bo': item.quantity > item.stock}">
                <div class="quantity">
      <button class="quantityChild" (click)="decreaseQuantity(item)" mat-icon-button *ngIf="mode == 'cart'">
        <mat-icon svgIcon="deleteCircle"></mat-icon>
      </button>
      <span *ngIf="mode != 'cart'"></span>
                <span class="quantityChild">{{item.quantity}}</span>
      <span *ngIf="mode != 'cart'"></span>
      <button class="quantityChild" (click)="increaseQuantity(item)" mat-icon-button *ngIf="mode == 'cart'">
        <mat-icon svgIcon="addCircle"></mat-icon>
      </button></div></td>
            <td mat-footer-cell *matFooterCellDef class="center">Totaal</td>
        </ng-container>
        <ng-container matColumnDef="subtotal">
            <th mat-header-cell *matHeaderCellDef>Subtotaal</th>
            <td mat-cell *matCellDef="let item">{{item.quantity*item.price | currency:'EUR'}}</td>
            <td mat-footer-cell *matFooterCellDef>{{ total | currency:'EUR'}}</td>
        </ng-container>
        <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let item"><button mat-icon-button (click)="delete(item.artcode)" *ngIf="mode == 'cart'"><mat-icon svgIcon="trash"></mat-icon></button></td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="display"></tr>
        <tr mat-row *matRowDef="let rowData; columns: display"></tr>
        <tr mat-footer-row *matFooterRowDef="displayFooter"></tr>
    </table>
    <mat-card *ngIf="zero" class="zero">
        <mat-card-header>
            <mat-card-title>Aantal staat op nul</mat-card-title>
        </mat-card-header>
    </mat-card>
    <mat-card *ngIf="bo" class="bo">
    <mat-card-title>Het bestelde aantal is groter dan het aantal in stock</mat-card-title>
        <mat-card-content>
            Je kan bij de verzendgegevens kiezen voor een extra of een uitgestelde zending.
        </mat-card-content>
    </mat-card>
<button mat-raised-button [routerLink]="'/checkout'" *ngIf="mode == 'cart'">Bestelling plaatsen</button>
<button mat-raised-button [routerLink]="'/cart'" *ngIf="mode == 'checkout'">Winkelwagen aanpassen</button>
</div>