<app-america-logos></app-america-logos>
<div id="product-data" *ngIf="productData | async as pd">
  <div class="buttons">
  <button
    mat-raised-button
    *ngFor="let i of counter(pd.pages); let ind = index"
    [routerLink]="navUrl + list + '/' + ind"
    [disabled]="page == ind"
  >
    {{ ind }}
  </button></div> 
  <app-product-list class="product-list" *ngIf="productList | async as pl"
    [productList]="pl"
    [supplier]="supplier"
  ></app-product-list>
  <div class="buttons">
  <button
    mat-raised-button
    *ngFor="let i of counter(pd.pages); let ind = index"
    [routerLink]="navUrl + list + '/' + ind"
    [disabled]="page == ind"
  >
    {{ ind }}
  </button></div>
</div>
 <div id="product" *ngIf="product | async as prod">
    <app-product [product]="prod" [mode]="'detail'"></app-product>
  </div> 

<app-chips [title]="'Merken'" [chipsType]="'brands'"></app-chips>
<app-chips [title]="'Tags'"></app-chips>