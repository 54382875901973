<mat-card>
  <mat-card-header>
    <mat-card-title *ngIf="title != ''">
      {{ title }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-chip-listbox aria-label="Product kenmerk selectie" (change)="chipChange($event)">
        <mat-chip-option *ngFor="let chip of chips | async">{{ chip | uppercase }}</mat-chip-option>
    </mat-chip-listbox>
  </mat-card-content>
</mat-card>
<app-product-list></app-product-list>