import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Product, ProductData } from '../types/shop';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  constructor(
    private httpClient: HttpClient,
    private route: ActivatedRoute
    ) {}
  baseUrl = '';
  getProductData = false;
  navUrl = '';
  urlProduct = '';
  list: string = '';
  page: number|null = 0;
  logo = '';
  supplier = '';
  productData: Observable<ProductData> = new Observable;
  productList: Observable<Array<Product>> = new Observable;
  product: Observable<Product> = new Observable;
  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      console.log('ProductsComponent params: ', params);
      if (params.has('supplier')) {
        this.getProductData = true;
        console.log('params has supplier');
        this.list = params.get('supplier') || '';
        this.baseUrl = '/assets/json/suppliers/';
        this.navUrl = '/supplier/';
      } else if (params.has('brand')) {
        this.getProductData = true;
        console.log('params has brand');
        this.list = params.get('brand') || '';
        this.baseUrl = '/assets/json/brands/';
        this.navUrl = '/brand/';
      } else if (params.has('chip')) {
        this.getProductData = true;
        console.log('params has chip');
        this.list = params.get('chip') || '';
        this.baseUrl = '/assets/json/chips/';
        this.navUrl = '/category/';
      } else if (params.has('artcode')) {
        console.log('params has artcode');
        this.urlProduct = `/assets/json/products/${params.get('artcode')}.json`;
        console.log('this.urlProduct: ', this.urlProduct);
      }
      let page = params.has('page') ? params.get('page') : ''+this.page;
      if (page !== null) {
      this.page = parseInt(page);
      } else {
        this.page = 0;
      }
      console.log(`list: ${this.list}, page: ${this.page}`);
        let urlProductData = `${this.baseUrl}${this.list}.json`;
        let urlList = `${this.baseUrl}${this.list}${this.page}.json`;
        if (this.getProductData) {
          this.productData = this.httpClient.get<ProductData>(urlProductData);
          this.productList = this.httpClient.get<Array<Product>>(urlList);
        } else {
          console.log('product is being fetched');
          this.product = this.httpClient.get<Product>(this.urlProduct);
          this.product.subscribe(result => console.log);
        }
    });
  }
    counter(i: number) {
      return new Array(i);
    }
}
