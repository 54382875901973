<mat-list>
  <mat-divider></mat-divider>
  <div mat-subheader>Openingsuren</div>
  <mat-list-item>
    <mat-icon matListItemIcon>access_time</mat-icon>
    <div matListItemLine>Maandag: 14u - 18u</div>
    <div matListItemLine>Dinsdag: gesloten</div>
    <div matListItemLine>Woensdag - Vrijdag: 9u - 12u30 & 14u - 18u</div>
    <div matListItemLine>Zaterdag: 10u - 12u30 & 13u30 - 18u</div>
  </mat-list-item>
  <mat-divider></mat-divider>
  <div mat-subheader>Contact</div>
  <mat-list-item>
    <mat-icon matListItemIcon>call</mat-icon>
    <div matListItemLine><a href="tel:+3252212812">052 21 28 12</a></div>
  </mat-list-item>
  <mat-list-item>
    <mat-icon matListItemIcon>email</mat-icon>
    <div matListItemLine>
      <a href="mailto:electroshop@microware.be">electroshop@microware.be</a>
    </div>
  </mat-list-item>
  <mat-divider></mat-divider>
  <div mat-subheader>Locatie</div>
  <mat-list-item>
    <mat-icon matListItemIcon>store</mat-icon>
    <div matListItemLine>
      <a href="https://goo.gl/maps/zSGr6i3NvTB2" target="blank"
        >Selexion Dendermonde - ElectroshopXL</a
      >
    </div>
    <div matListItemLine>Stationsstraat 28</div>
    <div matListItemLine>9200 Dendermonde</div>
    <div matListItemLine>België</div>
  </mat-list-item>
  <mat-divider></mat-divider>
  <div mat-subheader>Overige info</div>
  <mat-list-item>
    <mat-icon matListItemIcon>business</mat-icon>
    <div matListItemLine>
      <span
        >ElectroshopXL.be is een divisie van
        <a href="https://www.microware.be">Microware Solutions bv</a></span
      >
    </div>
    <div matListItemLine>Ondernemingsnummer: BE 0419.351.190</div>
  </mat-list-item>
</mat-list>
