<h1>Betaling succesvol</h1>
<p>
  De betalingsprocedure werd succesvol afgerond, hieronder vind je de gegevens
  met betrekking tot je bestelling.
</p>

<app-cart mode="success" [data]="cartItems"></app-cart>
<div id="pickupdata" *ngIf="pickupData">
  <h2>Afhaal gegevens</h2>
  <div class="userdata">
  <span>Voornaam: </span><span>{{ pickupData.firstName }}</span>
  <span>Familienaam: </span><span>{{ pickupData.lastName }}</span>
  <span>Telefoon: </span><span>{{ pickupData.phone }}</span>
  <span>E-mail: </span><span>{{ pickupData.email }}</span>
</div></div>
<div id="invoiceData" *ngIf="invoiceData">
  <h2>Factuur gegevens</h2>
  <div class="userdata">
  <span>Bedrijf: </span><span>{{ invoiceData.company }}</span>
  <span>BTW/ Ondernemingsnr.: </span><span>{{ invoiceData.vat }}</span>
  <span>Voornaam </span><span>{{ invoiceData.firstName }}</span>
  <span>Familienaam: </span><span>{{ invoiceData.lastName }}</span>
  <span>Telefoon: </span><span>{{ invoiceData.phone }}</span>
  <span>E-mail : </span><span>{{ invoiceData.email }}</span>
  <span>Land: </span><span>{{ invoiceData.country }}</span>
  <span>Postcode: </span><span>{{ invoiceData.zip }}</span>
  <span>Gemeente: </span><span>{{ invoiceData.city }}</span>
  <span>Straat: </span><span>{{ invoiceData.street }}</span>
  <span>Nummer: </span><span>{{ invoiceData.number }}</span>
  <span>Bus: </span><span>{{ invoiceData.box }}</span>
</div></div>
<div id="shippingData" *ngIf="shippingData">
  <h2>Verzend gegevens</h2>
  <div class="userdata">
  <span>Naam: </span><span>{{ shippingData.recipient }}</span>
  <span>Contactpersoon: </span><span>{{ shippingData.contact }}</span>
  <span>Telefoon: </span><span>{{ shippingData.phone }}</span>
  <span>E-mail : </span><span>{{ shippingData.email }}</span>
  <span>Land: </span><span>{{ shippingData.country }}</span>
  <span>Postcode: </span><span>{{ shippingData.zip }}</span>
  <span>Gemeente: </span><span>{{ shippingData.city }}</span>
  <span>Straat: </span><span>{{ shippingData.street }}</span>
  <span>Nummer: </span><span>{{ shippingData.number }}</span>
  <span>Bus: </span><span>{{ shippingData.box }}</span>
</div></div>
