import { Component, Input, OnInit } from '@angular/core';
import { MatChipEvent, MatChipListboxChange } from '@angular/material/chips';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ProductService } from '../product.service';

@Component({
  selector: 'app-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss']
})
export class ChipsComponent implements OnInit {
  @Input() chipsType = 'chips';
  @Input() title = '';
  chips: Observable<Array<string>> = new Observable<Array<string>>;
  constructor(
    private productService: ProductService,
    private router: Router
  ){
  }
  ngOnInit(): void {
      console.log('ChipsComponent chipsType: ', this.chipsType);
      if (this.chipsType == 'chips') {
        this.chips = this.productService.getChips(); 
      } else if (this.chipsType == 'brands') {
        this.chips = this.productService.getBrands();
      } else {
        this.chips = of([]);
      }
  }
  chipChange(event: MatChipListboxChange) {
    console.log('ChipsComponent chipChange event: ', event);
    let chip = event.value;
    if (this.chipsType == 'chips') {
      this.router.navigate([`/category/${chip.toLowerCase()}`]);
    } else if (this.chipsType == 'brands') {
      this.router.navigate([`/brand/${chip.toLowerCase()}`]);
    }
  }
}
