import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ShopService } from './shop.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  url = ''
  cartLines: number = 0;
  constructor(
    private breakpointObserver: BreakpointObserver,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private shopService: ShopService
    ) {
    breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.TabletPortrait
    ]).subscribe(result => {
      console.log(result);
      if (result.matches) {
        // this.hideMenu();
      }
    })
    iconRegistry.addSvgIcon('addToCart', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/add_cart.svg'));
    iconRegistry.addSvgIcon('addCircle', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/add_circle.svg'));
    iconRegistry.addSvgIcon('cart', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/cart.svg'));
    iconRegistry.addSvgIcon('contentCopy', 
    sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/content_copy.svg'));
    iconRegistry.addSvgIcon('deleteCircle', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/delete_circle.svg'));
    iconRegistry.addSvgIcon('home', 
    sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/home.svg'));
    iconRegistry.addSvgIcon('info', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/info.svg'));
    iconRegistry.addSvgIcon('link', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/link.svg'));
    iconRegistry.addSvgIcon('openInNew', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/open_in_new.svg'));
    iconRegistry.addSvgIcon('opIsOp', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/opisop.svg'));
    iconRegistry.addSvgIcon('thumbUp', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/thumb_up.svg'));
    iconRegistry.addSvgIcon('trash', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/trash.svg'));
    
  }

  ngOnInit(): void {
    this.shopService.getCart().subscribe(cart => {
      if (cart) {
        this.cartLines = cart.items.length;
      }
    })
  }
}
