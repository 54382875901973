import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LayoutModule } from '@angular/cdk/layout';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { MenuComponent } from './menu/menu.component';
import { CoreModule } from './core/core.module';
import { FooterComponent } from './footer/footer.component';
import { ProductsComponent } from './products/products.component';
import { ProductComponent } from './product/product.component';
import { HttpClientModule } from '@angular/common/http';
import { ProductListComponent } from './product-list/product-list.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AboutComponent } from './about/about.component';
import { ChipsComponent } from './chips/chips.component';
import { RandomProductsComponent } from './random-products/random-products.component';
import { AmericaLogosComponent } from './america-logos/america-logos.component';
import { SupplierLogosComponent } from './supplier-logos/supplier-logos.component';
import { BrandLogosComponent } from './brand-logos/brand-logos.component';
import { CartComponent } from './cart/cart.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { CheckoutFormComponent } from './checkout-form/checkout-form.component';
import { CheckoutSuccessComponent } from './checkout-success/checkout-success.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SidenavComponent,
    MenuComponent,
    FooterComponent,
    ProductsComponent,
    ProductComponent,
    ProductListComponent,
    PageNotFoundComponent,
    AboutComponent,
    ChipsComponent,
    RandomProductsComponent,
    AmericaLogosComponent,
    SupplierLogosComponent,
    BrandLogosComponent,
    CartComponent,
    CheckoutComponent,
    CheckoutFormComponent,
    CheckoutSuccessComponent,
  ],
  imports: [
    BrowserModule,
    CoreModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
