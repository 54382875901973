import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { CartComponent } from './cart/cart.component';
import { CheckoutSuccessComponent } from './checkout-success/checkout-success.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { HomeComponent } from './home/home.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ProductsComponent } from './products/products.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'cart', component: CartComponent },
  { path: 'checkout', component: CheckoutComponent },
  { path: 'checkout-success', component: CheckoutSuccessComponent },
  { path: 'product', component: ProductsComponent },
  { path: 'product/:artcode', component: ProductsComponent },
  { path: 'brand', component: ProductsComponent },
  { path: 'brand/:brand', component: ProductsComponent },
  { path: 'brand/:brand/:page', component: ProductsComponent },
  { path: 'category', component: ProductsComponent },
  { path: 'category/:chip', component: ProductsComponent },
  { path: 'category/:chip/:page', component: ProductsComponent },
  { path: 'supplier/:supplier', component: ProductsComponent },
  { path: 'supplier/:supplier/:page', component: ProductsComponent },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
