import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CartItem } from '../types/shop';
import { ShopService } from '../shop.service';
import {
  InvoiceData,
  PickupData,
  ShippingData,
  StripeSession,
} from '../types/shop';

const cname = 'CheckoutSuccessComponent';

@Component({
  selector: 'app-checkout-success',
  templateUrl: './checkout-success.component.html',
  styleUrls: ['./checkout-success.component.scss'],
})
export class CheckoutSuccessComponent implements OnInit {
  session: StripeSession | null = null;
  cartItems: Array<CartItem> = [];
  items: Array<CartItem> = [];
  invoiceData: InvoiceData | null = null;
  shippingData: ShippingData | null = null;
  pickupData: PickupData | null = null;
  constructor(
    private route: ActivatedRoute,
    private shopService: ShopService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      console.log('CheckoutSuccessComponent onInit queryParams: ', params);
      if (params && params.id) {
        this.shopService
          .getCheckoutSessionDetails(params.id)
          .subscribe((session: StripeSession) => {
            console.log(cname, ' onInit checkoutSession: ', session);
            this.session = { ...session };
            if (session.metadata && session.line_items) {
              this.invoiceData = JSON.parse(session.metadata.invoiceData);
              this.shippingData = JSON.parse(session.metadata.shippingData);
              this.pickupData = JSON.parse(session.metadata.pickupData);
              let lineItems = [...session.line_items.data];
              let itemsExtraInfo = [...JSON.parse(session.metadata.items)];
              let items: Array<CartItem> = [];
              for (let [index, item] of lineItems.entries()) {
                let artcode = item.description.split(' ')[0];
                let description = item.description.replace(artcode + ' ', '');
                let image = itemsExtraInfo[index].im;
                let price = item.price.unit_amount / 100;
                let quantity = item.quantity;
                let stock = itemsExtraInfo[index].st;
                items.push({
                  artcode,
                  description,
                  image,
                  price,
                  quantity,
                  stock,
                });
              }
              console.log(
                'CheckoutSuccessComponent onInit this.items: ',
                this.items
              );
              console.log('CheckoutSuccessComponent: items: ', items);
              this.cartItems = [...items];
            }
          });
      }
    });
  }
}
