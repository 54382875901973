import { Component } from '@angular/core';

@Component({
  selector: 'app-america-logos',
  templateUrl: './america-logos.component.html',
  styleUrls: ['./america-logos.component.scss']
})
export class AmericaLogosComponent {

}
