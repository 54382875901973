import { Component, EventEmitter, OnInit } from '@angular/core';
import {
  Form,
  FormBuilder,
  FormControl,
  NonNullableFormBuilder,
  Validators,
} from '@angular/forms';
import { Cart } from '../types/shop';
import { ShopService } from '../shop.service';
import {
  InvoiceData,
  PickupData,
  ShippingData,
  ShippingOption,
} from '../types/shop';

@Component({
  selector: 'app-checkout-form',
  templateUrl: './checkout-form.component.html',
  styleUrls: ['./checkout-form.component.scss'],
})
export class CheckoutFormComponent implements OnInit {
  cart: Cart;
  get shippingOption() {
    return this;
  }
  shippingOptions: Array<ShippingOption> = [];
  constructor(private fb: FormBuilder, private shopService: ShopService) {
    this.cart = {
      name: '',
      created: 0,
      hasBo: false,
      items: [],
    };
  }
  countries: Array<string> = [];
  cartTotal = 0;
  freeShipping = 0;
  shippingAmount = 0;
  shippingCost = 0;
  shippingSelected = false;
  getInvoiceData = false;
  getShippingData = false;
  getPickupData = false;
  localData = false;
  saveData = false;
  cartAmount = 0;
  invoiceForm = this.fb.group({
    company: [''],
    vat: [''],
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    phone: [
      '',
      [Validators.required, Validators.pattern(/^\+?\s*\d(\s*\d){8,12}\s*$/)],
    ],
    email: ['', [Validators.required, Validators.email]],
    street: ['', Validators.required],
    number: ['', Validators.required],
    box: [''],
    country: ['', Validators.required],
    city: ['', Validators.required],
    zip: ['', Validators.required],
  });

  pickupForm = this.fb.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    phone: [
      '',
      [Validators.required, Validators.pattern(/^\+?\s*\d(\s*\d){8,12}\s*$/)],
    ],
    email: ['', [Validators.required, Validators.email]],
  });

  shippingForm = this.fb.group({
    extraShipment: [false, Validators.required],
    recipient: ['', Validators.required],
    contact: [''],
    phone: [
      '',
      [Validators.required, Validators.pattern(/^\+?\s*\d(\s*\d){8,12}\s*$/)],
    ],
    email: ['', [Validators.required, Validators.email]],
    street: ['', Validators.required],
    number: ['', Validators.required],
    box: [''],
    country: ['', Validators.required],
    city: ['', Validators.required],
    zip: ['', Validators.required],
  });

  ngOnInit(): void {
    this.shopService
      .getShippingData()
      .subscribe((shippingData: ShippingData | null) => {
        if (shippingData) {
          if (!shippingData.hasOwnProperty('extraShipment')) {
            shippingData.extraShipment = false;
          }

          this.shippingForm.patchValue({ ...shippingData });
        }
      });
    this.shopService
      .getInvoiceData()
      .subscribe((invoiceData: InvoiceData | null) => {
        if (invoiceData) {
          this.invoiceForm.patchValue({ ...invoiceData });
        }
      });
    this.shopService
      .getPickupData()
      .subscribe((pickupData: PickupData | null) => {
        if (pickupData) {
          this.pickupForm.patchValue({ ...pickupData });
        }
      });
    this.shopService.getCart().subscribe((cart: Cart | null) => {
      if (cart) {
        this.cart = { ...cart };
        for (const item of cart.items) {
          this.cartAmount += item.quantity * item.price;
        }
        if (this.getShippingData) {
          this.calculateShipping();
        }
      }
    });
    this.shopService
      .getShippingOptions()
      .subscribe((options: Array<ShippingOption>) => {
        this.shippingOptions = [...options];
        for (const option of options) {
          console.log(option);
          if (option.country != 'xx') {
            this.countries.push(option.name);
          }
        }
      });
    this.shippingForm.controls.country.disable();
    this.shopService.getLocalDataStatus().subscribe((localData: boolean) => {
      this.localData = localData;
    });
  }
  calculateShipping() {
    this.shippingCost = 0;
    if (this.getShippingData) {
      let extraShipment = this.shippingForm.get('extraShipment')?.value;
      console.log(
        'CheckoutFormComponent calculateShipping extraShipment value: ',
        extraShipment
      );
      if (this.cartAmount < this.freeShipping) {
        this.shippingCost = this.shippingAmount;
      }
      if (extraShipment) {
        this.shippingCost += this.shippingAmount;
      }
    }
  }
  changeShipping(e: any) {
    console.log('CheckoutFormComponent changeShipping event: ', e);
    this.shippingSelected = true;
    if (e.value.country == 'xx') {
      this.getShippingData = false;
      this.getPickupData = true;
    } else {
      this.getShippingData = true;
      this.getPickupData = false;
      this.shippingForm.patchValue({
        country: e.value.country,
      });
      console.log(
        'CheckoutFormComponent changeShipping this.shippingForm.getRawValue(): ',
        this.shippingForm.getRawValue()
      );
    }
    this.freeShipping = e.value.freeShipping;
    this.shippingAmount = e.value.amount;
    this.calculateShipping();
  }
  toggleInvoiceData(e: boolean) {
    //    console.log('CheckoutFormComponent toggleShippingData e', e);
    this.getInvoiceData = e;
  }
  processForms() {
    if (
      (this.shippingForm.valid || !this.getShippingData) &&
      (this.invoiceForm.valid || !this.getInvoiceData) &&
      (this.pickupForm.valid || !this.getPickupData)
    ) {
      console.log('CheckoutFormComponent form(s) valid');
      if (this.getShippingData) {
        let shippingData: ShippingData =
          this.shippingForm.getRawValue() as ShippingData;
        console.log(
          'CheckoutFormComponent processForms shippingData: ',
          shippingData
        );
        if (this.saveData) {
          this.shopService.saveLocalData(
            'exl-shippingData',
            JSON.stringify(shippingData)
          );
          this.localData = true;
        }
        this.shopService.setShippingData(shippingData);
      }
      if (this.getInvoiceData) {
        let invoiceData: InvoiceData = this.invoiceForm.value as InvoiceData;
        if (this.saveData) {
          this.shopService.saveLocalData(
            'exl-invoiceData',
            JSON.stringify(invoiceData)
          );
          this.localData = true;
        }
        this.shopService.setInvoiceData(invoiceData);
      }
      if (this.getPickupData) {
        let pickupData: PickupData = this.pickupForm.value as PickupData;
        if (this.saveData) {
          this.shopService.saveLocalData(
            'exl-pickupData',
            JSON.stringify(this.pickupForm.value)
          );
          this.localData = true;
        }
        this.shopService.setPickupData(pickupData);
      }
      console.log('start payWithStripe()');
      this.shopService.payWithStripe();
    }
  }
  deleteLocalData(): void {
    this.shopService.deleteLocalData('exl-shippingData');
    this.shopService.deleteLocalData('exl-invoiceData');
    this.shopService.deleteLocalData('exl-pickupData');
    this.localData = false;
  }
  saveUserData(e: boolean): void {
    this.saveData = e;
  }
}
