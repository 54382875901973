<div id="logos">
  <div>
    <a routerLink="/supplier/adafruit"
      ><img src="assets/img/supplier_logos/adafruit_logo.jpg" alt="Adafruit"
    /></a>
  </div>
  <div>
    <a routerLink="/supplier/sparkfun"
      ><img src="assets/img/supplier_logos/sparkfun_logo.jpg" alt="Sparkfun"
    /></a>
  </div>
  <div>
    <a routerLink="/supplier/pololu"
      ><img src="assets/img/supplier_logos/pololu_logo.jpg" alt="Pololu"
    /></a>
  </div>
</div>
