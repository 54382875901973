import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { loadStripe } from '@stripe/stripe-js';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Cart, CartItem } from './types/shop';
import {
  CheckoutCustomer,
  CheckoutData,
  ShippingOption,
  StripeSessionId,
  InvoiceData,
  PickupData,
  ShippingData,
} from './types/shop';

const {
  checkoutUrl,
  getCheckoutSessionDetailsUrl,
  getPaymentIntentDetailsUrl,
  stripePublicKey
} = environment;


const headers = new HttpHeaders().set('Access-Control-Allow-Origin', '*');
const shippingOptions: Array<ShippingOption> = [
  {
    name: 'Afhalen in de winkel',
    country: 'xx',
    amount: 0,
    freeShipping: 0,
  },
  {
    name: 'België',
    country: 'BE',
    amount: 5.9,
    freeShipping: 75,
  },
  {
    name: 'Nederland',
    country: 'NL',
    amount: 7.5,
    freeShipping: 150,
  },
];
@Injectable({
  providedIn: 'root',
})
export class ShopService {
  private cartSubject = new BehaviorSubject<null | Cart>(null);
  private shippingOptionsSubject = new BehaviorSubject<Array<ShippingOption>>(
    shippingOptions
  );
  private cart: Cart;
  private pickupData: PickupData | null = null;
  private shippingData: ShippingData | null = null;
  private invoiceData: InvoiceData | null = null;
  private pickupDataSubject = new BehaviorSubject<PickupData | null>(
    this.pickupData
  );
  private shippingDataSubject = new BehaviorSubject<ShippingData | null>(
    this.shippingData
  );
  private invoiceDataSubject = new BehaviorSubject<InvoiceData | null>(
    this.invoiceData
  );
  private localDataStatusSubject = new BehaviorSubject<boolean>(false);
  constructor(private httpClient: HttpClient) {
    let carts = this.getFromLocalStorage('exl-carts');
    if (carts) {
      this.cart = carts[0];
      if (!this.cart.hasOwnProperty('extraShipment')) {
        this.cart.hasBo = this.checkBo(this.cart.items);
      }
      console.log('ShopService constructor carts: ', carts);
      console.log('ShopService constructor this.cart: ', this.cart);
    } else {
      this.cart = {
        name: 'cart',
        created: Date.now(),
        hasBo: false,
        items: [],
      };
    }
    this.saveCart();
    let localData = false;
    let shippingData = this.getFromLocalStorage('exl-shippingData');
    if (shippingData) {
      this.shippingDataSubject.next({ ...shippingData });
      localData = true;
    }
    let invoiceData = this.getFromLocalStorage('exl-invoiceData');
    if (invoiceData) {
      this.invoiceDataSubject.next({ ...invoiceData });
      localData = true;
    }
    let pickupData = this.getFromLocalStorage('exl-pickupData');
    if (pickupData) {
      this.pickupDataSubject.next({ ...pickupData });
      localData = true;
    }
    this.localDataStatusSubject.next(localData);
  }
  private checkBo(items: Array<CartItem>): boolean {
    let hasBo = false;
    for (let item of items) {
      if (item.stock && item.quantity > item.stock) {
        hasBo = true;
      }
    }
    console.log('ShopService checkBo: ', hasBo);
    return hasBo;
  }
  private saveCart() {
    this.cart.hasBo = this.checkBo(this.cart.items);
    console.log('ShopService saveCart this.cart: ', this.cart);
    this.saveToLocalStorage('exl-carts', JSON.stringify([this.cart]));
    this.cartSubject.next({ ...this.cart });
  }
  private getFromLocalStorage(name: string) {
    let item = null;
    let savedItem = localStorage.getItem(name);
    if (savedItem) {
      item = JSON.parse(savedItem);
    }
    return item;
  }
  private removeFromLocalStorage(name: string) {
    localStorage.removeItem(name);
  }
  private saveToLocalStorage(name: string, item: string) {
    console.log('ShopService saveToLocalStorage name: ', name, ' item: ', item);
    localStorage.setItem(name, item);
  }
  private getStripeCheckoutData(): CheckoutData | null {
    let email = '';
    let country = '';
    let phone = '';
    let shipping = true;
    let extraShipment = false;
    console.log(
      'ShopService getStripeCheckoutData this.pickupData: ',
      this.pickupData
    );
    console.log(
      'ShopService getStripeCheckoutData this.invoiceData: ',
      this.invoiceData
    );
    console.log(
      'ShopService getStripeCheckoutData this.shippingData: ',
      this.shippingData
    );
    if (this.pickupData) {
      email = this.pickupData.email;
      phone = this.pickupData.phone;
      country = 'BE';
      shipping = false;
    } else if (this.shippingData) {
      email = this.shippingData.email;
      country = this.shippingData.country;
      extraShipment = this.shippingData.extraShipment;
    } else {
      return null;
    }
    let customer: CheckoutCustomer = {
      country,
      email,
      phone,
    };
    let itemsArray = [...this.cart.items].map((item) => {
      return {
        ac: item.artcode,
        im: item.image,
        st: item.stock,
      };
    });
    let items = JSON.stringify(itemsArray);
    let lineItems = [];
    for (const item of this.cart.items) {
      lineItems.push({
        id: '' + item.artcode,
        images: [],
        name: item.description,
        price: item.price,
        quantity: item.quantity,
        sku: '' + item.artcode,
      });
    }
    return {
      customer,
      lineItems,
      shipping,
      extraShipment,
      items,
    };
  }
  getCart(): Observable<null | Cart> {
    return this.cartSubject.asObservable();
  }
  addToCart(newItem: CartItem) {
    //console.log('shopService addToCart item: ', item);
    let isNew = true;
    for (const item of this.cart.items) {
      if (item.artcode == newItem.artcode) {
        isNew = false;
        item.quantity += newItem.quantity;
      }
    }
    if (isNew) {
      this.cart.items.push(newItem);
    }
    //console.log('ShopService addToCart this.cart: ', this.cart);
    this.saveCart();
    // TODO
  }
  decreaseQuantity(item: CartItem) {
    if (item.quantity > 0) {
      item.quantity--;
      let cartItems = this.cart.items;
      for (const [index, value] of cartItems.entries()) {
        if (value.artcode == item.artcode) {
          cartItems[index].quantity = item.quantity;
        }
      }
      this.saveCart();
    } else {
      this.deleteFromCart(item.artcode);
    }
  }
  increaseQuantity(item: CartItem) {
    if (item.maxQuantity && item.quantity < item.maxQuantity) {
      item.quantity++;
      let cartItems = this.cart.items;
      let newItem = true;
      for (const [index, value] of cartItems.entries()) {
        if (value.artcode == item.artcode) {
          cartItems[index].quantity = item.quantity;
          newItem = false;
          break;
        }
      }
      if (newItem) {
        item.quantity = 1;
        cartItems.push(item);
      }
      this.saveCart();
    }
  }
  deleteFromCart(artcode: string) {
    let cartItems = this.cart.items;
    for (const [index, value] of cartItems.entries()) {
      if (value.artcode == artcode) {
        cartItems.splice(index, 1);
        break;
      }
    }
    this.saveCart();
    // TODO
  }
  getLocalData(name: string) {
    return this.getFromLocalStorage(name);
  }
  deleteLocalData(name: string) {
    this.removeFromLocalStorage(name);
  }
  saveLocalData(name: string, data: string) {
    this.saveToLocalStorage(name, data);
  }
  createCheckoutSession(data: CheckoutData): Observable<StripeSessionId> {
    console.log('ShopService CreateCheckoutSession data: ', data);
    let metadataItems = data.items;
    let metadata = {
      invoiceData: JSON.stringify(this.invoiceData),
      shippingData: JSON.stringify(this.shippingData),
      pickupData: JSON.stringify(this.pickupData),
      extraShipment: data.extraShipment,
      items: data.items,
    };
    const checkout_data = {
      customer: data.customer,
      line_items: data.lineItems,
      shipping: data.shipping,
      metadata,
    };
    console.log(
      'ShopService CreateCheckoutSession checkout_data: ',
      checkout_data
    );
    return this.httpClient.post<StripeSessionId>(checkoutUrl, checkout_data);
  }
  getCheckoutSessionDetails(id: string) {
    const queryData = {
      id,
    };
    return this.httpClient.post<any>(getCheckoutSessionDetailsUrl, queryData);
  }
  getPaymentIntent(id: string) {
    const queryData = {
      id,
    };
    return this.httpClient.post<any>(getPaymentIntentDetailsUrl, queryData);
  }
  getInvoiceData() {
    return this.invoiceDataSubject.asObservable();
  }
  getPickupData() {
    return this.pickupDataSubject.asObservable();
  }
  getShippingData() {
    return this.shippingDataSubject.asObservable();
  }
  getLocalDataStatus() {
    return this.localDataStatusSubject.asObservable();
  }
  getShippingOptions() {
    return this.shippingOptionsSubject.asObservable();
  }
  setInvoiceData(data: InvoiceData) {
    this.invoiceData = { ...data };
    this.invoiceDataSubject.next({ ...data });
  }
  setPickupData(data: PickupData) {
    this.pickupData = { ...data };
    this.pickupDataSubject.next({ ...data });
  }
  setShippingData(data: ShippingData) {
    this.shippingData = { ...data };
    this.shippingDataSubject.next({ ...data });
  }
  async payWithStripe() {
    let checkoutData: CheckoutData | null = this.getStripeCheckoutData();
    console.log('payWithStripe checkoutData: ', checkoutData);
    if (checkoutData) {
      const stripe = await loadStripe(stripePublicKey);
      if (stripe) {
        this.createCheckoutSession(checkoutData).subscribe((data) => {
          console.log('stripe checkoutData: ', data);
          stripe.redirectToCheckout({ sessionId: data.id });
        });
      }
    }
  }
}
