import { Component } from '@angular/core';

@Component({
  selector: 'app-brand-logos',
  templateUrl: './brand-logos.component.html',
  styleUrls: ['./brand-logos.component.scss']
})
export class BrandLogosComponent {

}
