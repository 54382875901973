import { Component, Input } from '@angular/core';
import { Product } from '../types/shop';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent {
  private _productList: Array<Product> = [];
  seller: string = '';
@Input() 
  get productList() {
    return [...this._productList];
  }
  set productList(products: Array<Product> | null) {
    if (products) {
      this._productList = products.map(product => {
        //console.log('ProductListComponent set productList products.map product: ', product);
        return product;
      })
    }
  }
@Input()
get supplier(): string { return this._supplier };
set supplier(supplier: string) {
  this.seller = supplier;
 this._supplier = supplier.charAt(0).toUpperCase() + supplier.slice(1);
};
_supplier: string = '';
}
