<div class="checkoutForm" *ngIf="getShippingData">
  <p>
    Gratis verzending ter waarde van
    {{ shippingAmount | currency : "EUR" }} vanaf:
    {{ freeShipping | currency : "EUR" }} <br />
  </p>
  <p>Verzendkosten: {{ shippingCost | currency : "EUR" }}<br /></p>
</div>
<h1>Verzendgegevens</h1>
<p class="small">* vereiste velden</p>
<div class="checkoutForm">
  <mat-form-field>
    <mat-label>Verzenden of afhalen</mat-label>
    <mat-select (selectionChange)="changeShipping($event)">
      <mat-option *ngFor="let option of shippingOptions" [value]="option">{{
        option.name
      }}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<form [formGroup]="pickupForm" class="checkoutForm" *ngIf="getPickupData">
  <h1>Contactgegevens</h1>
  <p class="small">* vereiste velden</p>
  <mat-form-field>
    <mat-label>Voornaam</mat-label>
    <input
      matInput
      type="text"
      placeholder="Voornaam"
      formControlName="firstName"
    />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Familienaam</mat-label>
    <input
      matInput
      type="text"
      placeholder="Familienaam"
      formControlName="lastName"
    />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Telefoonnummer</mat-label>
    <input
      matInput
      type="text"
      placeholder="bijvoorbeeld +32 52 212812"
      formControlName="phone"
    />
  </mat-form-field>
  <mat-form-field>
    <mat-label>E-mail adres</mat-label>
    <input matInput type="text" placeholder="E-mail" formControlName="email" />
  </mat-form-field>
</form>
<form [formGroup]="shippingForm" class="checkoutForm" *ngIf="getShippingData">
  <mat-checkbox *ngIf="cart.hasBo; else elseBlock" formControlName="extraShipment" (change)="calculateShipping()">Extra zending (+{{shippingAmount | currency : "EUR"}})</mat-checkbox>
  <ng-template #elseBlock><input type="hidden" name="es" formControlName="extraShipment" value="true"></ng-template>
  <mat-form-field>
    <mat-label>Naam ontvanger of bedrijf</mat-label>
    <input
      matInput
      placeholder="Naam van de ontvanger of bedrijf"
      formControlName="recipient"
    />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Contactpersoon</mat-label>
    <input
      matInput
      type="text"
      placeholder="Contactpersoon"
      formControlName="contact"
    />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Telefoonnummer</mat-label>
    <input
      matInput
      type="text"
      placeholder="bijvoorbeeld +32 52 212812"
      formControlName="phone"
    />
  </mat-form-field>
  <mat-form-field>
    <mat-label>E-mail adres</mat-label>
    <input matInput type="text" placeholder="E-mail" formControlName="email" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Land:</mat-label>
    <input matInput type="text" formControlName="country" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Postcode</mat-label>
    <input matInput type="text" placeholder="Postcode" formControlName="zip" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Gemeente</mat-label>
    <input matInput type="text" placeholder="Gemeente" formControlName="city" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Straat</mat-label>
    <input matInput type="text" placeholder="Straat" formControlName="street" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Nummer</mat-label>
    <input matInput type="text" placeholder="Nummer" formControlName="number" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Bus</mat-label>
    <input matInput type="text" placeholder="Bus" formControlName="box" />
  </mat-form-field>
</form>
<div class="checkoutForm">
  {{ pickupForm.status }}
</div>
<div class="checkoutForm">
  <mat-checkbox
    [checked]="getInvoiceData"
    (change)="toggleInvoiceData($event.checked)"
    [disabled]="shippingForm.invalid && pickupForm.invalid"
    >Factuurgegevens opgeven
  </mat-checkbox>
</div>
<form [formGroup]="invoiceForm" class="checkoutForm" *ngIf="getInvoiceData">
  <h1>Factuurgegevens</h1>
  <p class="small">* vereiste velden</p>
  <mat-form-field>
    <mat-label>Bedrijf</mat-label>
    <input matInput placeholder="Bedrijfsnaam" formControlName="company" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>BTW / Ondernemingsnummer</mat-label>
    <input
      matInput
      placeholder="BTW / Ondernemingsnummer"
      formControlName="vat"
    />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Voornaam</mat-label>
    <input
      matInput
      type="text"
      placeholder="Voornaam"
      formControlName="firstName"
    />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Familienaam</mat-label>
    <input
      matInput
      type="text"
      placeholder="Familienaam"
      formControlName="lastName"
    />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Telefoonnummer</mat-label>
    <input
      matInput
      type="text"
      placeholder="bijvoorbeeld +32 52 212812"
      formControlName="phone"
    />
  </mat-form-field>
  <mat-form-field>
    <mat-label>E-mail adres</mat-label>
    <input matInput type="text" placeholder="E-mail" formControlName="email" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Land</mat-label>
    <mat-select formControlName="country">
      <mat-option *ngFor="let country of countries" [value]="country">{{
        country
      }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Postcode</mat-label>
    <input matInput type="text" placeholder="Postcode" formControlName="zip" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Gemeente</mat-label>
    <input matInput type="text" placeholder="Gemeente" formControlName="city" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Straat</mat-label>
    <input matInput type="text" placeholder="Straat" formControlName="street" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Nummer</mat-label>
    <input matInput type="text" placeholder="Nummer" formControlName="number" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Bus</mat-label>
    <input matInput type="text" placeholder="Bus" formControlName="box" />
  </mat-form-field>
</form>
<div class="checkoutForm">
  <mat-checkbox
    (change)="saveUserData($event.checked)"
    [checked]="saveData"
    [disabled]="
      !(
        (shippingForm.valid || !getShippingData) &&
        (invoiceForm.valid || !getInvoiceData) &&
        (pickupForm.valid || !getPickupData)
      )
    "
    >Bewaar/update mijn gegevens in deze browser.</mat-checkbox
  >
</div>
<div class="checkoutForm" *ngIf="localData">
  <button mat-icon-button (click)="deleteLocalData()">
    <mat-icon svgIcon="trash"></mat-icon>
  </button>
  <span (click)="deleteLocalData()" class="click"
    >Verwijder mijn gegevens uit deze browser.</span
  >
</div>
<mat-divider></mat-divider>
<div class="checkoutForm">
  <button class="nextButton" mat-raised-button (click)="processForms()"
    [disabled]="
      !(
        (shippingForm.valid || !getShippingData) &&
        (invoiceForm.valid || !getInvoiceData) &&
        (pickupForm.valid || !getPickupData) &&
        shippingSelected
      )
    ">
    Betalen
  </button>
</div>
