<mat-card>
  <mat-card-header>
   <mat-card-title>ElectroshopXL.be</mat-card-title> 
   <mat-card-subtitle> Elektronica onderdelen, modules, Whadda en andere Velleman producten, kabels & connectoren, voedingen, ...</mat-card-subtitle>
    </mat-card-header>

  <mat-card-content>
    <p>
      Contacteer ons of bezoek onze winkel in Dendermonde voor meer info over
      onze producten.
    </p>
  </mat-card-content>
</mat-card>
    <app-chips [chipsType]="'brands'" [title]="'Merken'"></app-chips>
    <app-random-products></app-random-products>
<mat-card>
  <mat-card-header>
    <mat-card-title> Uitverkoop Adafruit, Sparkfun en Pololu </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p>
      Onderstaande pagina's geven een overzicht van welke producten we nog in
      voorraad hebben van Adafruit, Sparkfun en Pololu, telkens met een link
      naar de productpagina van de leverancier. Bij deze leveranciers wordt niet
      meer besteld, <strong>op==op.</strong>
    </p>
    <p>
      U kan uw bestelling plaatsen via een e-mail naar 
      <a href="mailto:electroshop@microware.be" target="_blank">electroshop@microware.be</a> ,
      noteer onze artikelcode (#xxxxx) en het aantal, de producten kunnen afgehaald of
      verzonden worden.
    </p>
  </mat-card-content>
  <app-america-logos></app-america-logos>
</mat-card>
<app-chips [title]="'Tags'"></app-chips>
