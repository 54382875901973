import { Component, Input, OnInit, Sanitizer } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CartItem } from '../types/shop';
import { Product } from '../types/shop';
import { ShopService } from '../shop.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit{
  private _product: Product;
  @Input() mode = 'list';
  image: string = '';
  quantity: number = 0;
  supplier: string = '';
  maxQuantity: number = 1;
  minQuantity: number = 1;
  quantityInCart: number = 0;
  seller: string = '';
  @Input()
  get product(): Product {
    return this._product;
  }
  set product(product: Product) {
    //console.log('ProductComponent product: ', product);
    this._product = { ...product };
    if (product.info) {
      this.info = this.sanitizer.bypassSecurityTrustHtml(product.info);
    }
    this.seller = product.supplier;
    this.supplier = this.seller.charAt(0).toUpperCase() + this.seller.slice(1);
    this.image = `/assets/img/${this.product.images[0]}`;
    if (product.status == 'E') {
      this.maxQuantity = product.stock;
    } else {
      this.maxQuantity = 1000;
    }
  }

  constructor(
    private sanitizer: DomSanitizer,
    private shopService: ShopService
    ) {
    this._product = {
      artcode: '',
      brand: '',
      description: '',
      images: [],
      price: 0,
      status: '',
      stock: 0,
      reference: '',
      supplier: '',
      url: '',
    };
      this.info = this.sanitizer.bypassSecurityTrustHtml('');
  }

  ngOnInit(): void {
      this.shopService.getCart().subscribe(cart => {
//        console.log('ProductComponent onInit cart: ', cart);
        if (cart) {
          let items: Array<CartItem> = [...cart.items];
          for (const item of items) {
            if (this.product.artcode == item.artcode) {
              this.quantity = item.quantity;
            }
          }
        }
      })
  }

  info: SafeHtml;
  getItem() {
    let {artcode, images, description, price, stock} = this.product;
    let item = {
      artcode,
      image: images[0],
      description,
      price,
      quantity: this.quantity,
      maxQuantity: this.maxQuantity,
      minQuantity: this.minQuantity,
      stock
    }
    console.log('ProductComponent getItem item: ', item);
    return item;
  }
  increaseQuantity() {
    this.shopService.increaseQuantity(this.getItem());
  }
  decreaseQuantity() {
    this.shopService.decreaseQuantity(this.getItem());
  }
}
