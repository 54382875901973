<mat-toolbar class="main-toolbar">
  <span>&nbsp;</span>
  <img [routerLink]="'/'" src="assets/ElectroshopXLlogo137x50.png" alt="ElectroshopXL Catalogus" />
  <span>
    <button mat-icon-button [routerLink]="'/cart'">
      <mat-icon svgIcon="cart" [matBadge]="cartLines" [matBadgeHidden]="cartLines == 0"></mat-icon>
    </button>
    <button mat-icon-button [routerLink]="'/'">
      <mat-icon svgIcon="home"></mat-icon>
    </button>
    <button mat-icon-button [routerLink]="'/about'">
      <mat-icon svgIcon="info"></mat-icon>
    </button>
  </span>
</mat-toolbar>
<div class="warning">DIT IS EEN TESTOMGEVING PLAATS HIER GEEN BESTELLINGEN</div>
<div class="main-content">
<div></div>
<div>
  <router-outlet></router-outlet></div>
<div></div>
</div>
<footer id="footer">
  <app-footer></app-footer>
</footer>
