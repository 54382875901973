import { Component } from '@angular/core';
import { ProductService } from '../product.service';

@Component({
  selector: 'app-supplier-logos',
  templateUrl: './supplier-logos.component.html',
  styleUrls: ['./supplier-logos.component.scss']
})
export class SupplierLogosComponent {
  constructor(
    private productService: ProductService
  ) {}

}
